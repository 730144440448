import {Button, Grid, Input, InputMask, Space, Typography} from "@express-24/theseus-ui";
import {
  $codeErrorMessage,
  $phone,
  getActivateCodeFx,
  loginSubmited,
  phoneChanged
} from "../../registration/model/model";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useStore} from "effector-react";
import logo from "../../../asserts/logo.svg";
import "./styles.css";

export const Login = () => {
  const {t} = useTranslation();
  const phone = useStore($phone);
  const codeErrorMessage = useStore($codeErrorMessage);
  const phoneConfirmSending = useStore(getActivateCodeFx.pending);

  const handlePhoneSubmit = useCallback((e) => {
    e.preventDefault();
    loginSubmited();
  }, []);

  return (
    <div className="registration">
      <Space padding={[16, 0, 0, 0]}>
        <form
          key='phoneConfirm'
          onSubmit={handlePhoneSubmit}
        >
          <div className="registration__section">
            <Grid.Row justify="center" align="center">
              <img src={logo} alt="logotype" />
              <h2 className="registration__title">
                {t('become_part_team')} <span>Express24</span>
              </h2>
            </Grid.Row>
            <Grid.Row justify="center">
              <div className="registration__description">
                {t('courier_condition')}
              </div>
            </Grid.Row>
          </div>
          <div className="registration__section">
            <Grid.Row gutter={[0, 16]}>
              <Grid.Column size={12}>
                <Typography
                  semibold
                  level='h3'
                >
                  {t('enter_phone_number')}
                </Typography>
                <Space margin={[4, 0, 0, 0]}/>
                <Typography
                  level='sub-text'
                  kind='secondary'
                >
                  {t('get_code_accept')}
                </Typography>
              </Grid.Column>
              <Grid.Column size={12}>
                <InputMask
                  defaultValue={phone}
                  onChange={phoneChanged}
                  mask='+999 99 999 99 99'
                >
                  <Input
                    fluid
                    allowClear
                    type='tel'
                    onChange={() => {
                    }}
                    label={t('phone_number') || ''}
                    status={codeErrorMessage ? 'error' : undefined}
                  />
                </InputMask>
              </Grid.Column>
              <Grid.Column size={12}>
                {/*<Typography*/}
                {/*  level='sub-text'*/}
                {/*  kind='secondary'*/}
                {/*>*/}
                {/*  {t('privacy_policy')}*/}
                {/*</Typography>*/}
                <Space margin={[16, 0, 0, 0]}/>
                <Button
                  fluid
                  type='submit'
                  size='large'
                  loading={phoneConfirmSending}
                  disabled={phone?.length !== 17 || phoneConfirmSending}
                >
                  {t('get_code')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </div>
        </form>
      </Space>
    </div>
  );
};