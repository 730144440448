import './locale';
import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import {history} from './custom-history';
import {Router} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
