import {ChangeEvent} from "react";
import {Gender, MovementTypes} from "./model.types";
import {notification} from "@express-24/theseus-ui";
import {Region} from "../../../shared/api/api.types";
import {combine, createEffect, createEvent, createStore, guard, sample} from "effector";
import {fetchRegions, postActivatePhone, postPhoneNumber, putRegisterApplication} from "../../../shared/api/requests";
import i18n from "i18next";
import {history} from '../../../custom-history';

export const pageOpened = createEvent();
export const pageClosed = createEvent();
export const loginSubmited = createEvent();
export const registrationStepChanged = createEvent();
export const confirmCodeChanged = createEvent<string>();
export const toggleCodeResend = createEvent();
export const codeConfirmed = createEvent();
export const codeReceivedAgain = createEvent();
export const nameChanged = createEvent<string>();
export const surnameChanged = createEvent<string>();
export const yearChanged = createEvent<string>();
export const monthChanged = createEvent<string>();
export const dayChanged = createEvent<string>();
export const genderSelected = createEvent<Gender>();
export const regionSelected = createEvent<number>();
export const deliveryTypeSelected = createEvent<MovementTypes>();
export const movementTypeNumberChanged = createEvent<ChangeEvent<HTMLInputElement>>();
export const movementTypeBrandChanged = createEvent<ChangeEvent<HTMLInputElement>>();
export const phoneChanged = createEvent<string>();
export const formSubmitted = createEvent();
export const goBackClicked = createEvent()

export const fetchRegionsFx = createEffect(fetchRegions);
export const getActivateCodeFx = createEffect(postPhoneNumber);
export const activatePhoneFx = createEffect(postActivatePhone);
const successNotificationFx = createEffect((msg: string) => {
  notification.success({title: msg, duration: 3000});
});
const errorNotificationFx = createEffect((msg: string) => {
  notification.error({title: msg, duration: 3000});
});
export const updateCourierDataFx = createEffect(putRegisterApplication);
export const fillAllErrorInputsFx = createEffect(() => {
  notification.error({title: i18n.t('fill_all_inputs'), duration: 3000});
});
export const redirectToPageFx = createEffect((path: string) => {
  history.push(path);
});
export const saveCourierIdFx = createEffect((courierId: number) => {
  localStorage.setItem('courierId', courierId.toString());
});
export const deleteCourierIdFx = createEffect(() => {
  localStorage.removeItem('courierId')
})
export const redirectToHomeFx = createEffect(() => {
  history.push('/')
})

export const $phone = createStore<string>('');
export const $code = createStore<string>('');
export const $registrationStep = createStore<string>('congrats');
export const $resendCodeStatus = createStore<boolean>(true);
export const $name = createStore<string | null>(null);
export const $surname = createStore<string | null>(null);
export const $year = createStore<string | null>(null);
export const $month = createStore<string | null>(null);
export const $day = createStore<string | null>(null);
export const $selectedGender = createStore<Gender | null>(null);
export const $selectedRegion = createStore<number | null>(null);
export const $selectedDeliveryType = createStore<MovementTypes | null>(null);
export const $regions = createStore<Region[] | null>(null);
export const $movementTypeNumber = createStore<string | null>(null);
export const $movementTypeBrand = createStore<string | null>(null);
export const $codeErrorMessage = createStore<string | null>(null);
export const $createdCourierId = createStore<number | null>(null);
export const $successfulCreatedText = createStore<string | null>(null);

export const $combinedInputs = combine({
  name: $name,
  last_name: $surname,
  gender: $selectedGender,
  additional_phone: $phone,
  region_id: $selectedRegion,
  year: $year,
  month: $month,
  day: $day,
  car_name: $movementTypeBrand,
  car_number: $movementTypeNumber,
  vehicle_type_id: $selectedDeliveryType,
  courierId: $createdCourierId
});


$phone
  .on(phoneChanged, (_, phone) => phone)
  .reset(pageClosed);
$registrationStep
  .on(registrationStepChanged, () => 'code-confirm')
  .on(activatePhoneFx.done, () => 'registration')
  .reset(updateCourierDataFx.pending);
$code
  .on(confirmCodeChanged, (_, code) => code)
  .reset([codeReceivedAgain, goBackClicked, activatePhoneFx.done])
$resendCodeStatus
  .on(toggleCodeResend, () => false)
  .reset(codeReceivedAgain);
$name
  .on(nameChanged, (_, str) => str)
  .reset(pageClosed);
$surname
  .on(surnameChanged, (_, str) => str)
  .reset(pageClosed);
$year
  .on(yearChanged, (_, year) => year)
  .reset(pageClosed);
$month
  .on(monthChanged, (_, month) => month)
  .reset(pageClosed);
$day
  .on(dayChanged, (_, day) => day)
  .reset(pageClosed);
$selectedGender
  .on(genderSelected, (_, gender) => gender)
  .reset(pageClosed);
$selectedRegion
  .on(regionSelected, (_, regionId) => regionId)
  .reset(pageClosed);
$selectedDeliveryType
  .on(deliveryTypeSelected, (_, deliveryType) => deliveryType)
  .reset(pageClosed);
$regions
  .on(fetchRegionsFx.doneData, (_, data) => data)
  .reset(pageClosed);
$movementTypeNumber
  .on(movementTypeNumberChanged, (_, {target}) => {
    if (target.value.length <= 15) {
      return target.value;
    }
  })
  .reset(pageClosed);
$movementTypeBrand
  .on(movementTypeBrandChanged, (_, {target}) => {
    if (target.value.length <= 50) {
      return target.value;
    }
  })
  .reset(pageClosed);
$codeErrorMessage
  //@ts-ignore
  .on(activatePhoneFx.fail, (_, {error}) => error.response.data.message)
  //@ts-ignore
  .on(getActivateCodeFx.fail, (_, {error}) => error.response.data.error)
  .reset(registrationStepChanged)
  .reset(pageClosed)
  .reset(goBackClicked)
$createdCourierId
  .on(activatePhoneFx.done, (_, {result}) => result.id)
  .reset(pageClosed);
$successfulCreatedText
  .on(updateCourierDataFx.done, (_, {result}) => result.data.message)
  .reset(pageClosed);


sample({
  clock: pageOpened,
  target: fetchRegionsFx
});

sample({
  source: $phone,
  clock: [loginSubmited, codeReceivedAgain],
  fn: (phone: string) => ({login: phone.replace(/\s/g, "")}),
  target: getActivateCodeFx
});

sample({
  source: $phone,
  clock: getActivateCodeFx.done,
  target: registrationStepChanged
});

sample({
  source: {
    phone: $phone,
    code: $code
  },
  clock: codeConfirmed,
  fn: ({phone, code}) => ({
    login: phone.replace(/\s/g, ""),
    code: Number(code.replace(/\s/g, ""))
  }),
  target: activatePhoneFx
});

sample({
  clock: activatePhoneFx.done,
  fn: ({result}) => result.message,
  target: successNotificationFx
});

sample({
  clock: activatePhoneFx.fail,
  //@ts-ignore
  fn: ({error}) => error.response.data.message,
  target: errorNotificationFx
});

sample({
  clock: getActivateCodeFx.fail,
  //@ts-ignore
  fn: ({error}) => error.response.data.error,
  target: errorNotificationFx
});

sample({
  source: $combinedInputs,
  clock: formSubmitted,
  filter: ({
             day,
             year,
             name,
             month,
             gender,
             car_name,
             last_name,
             region_id,
             courierId,
             car_number,
             vehicle_type_id,
             additional_phone
           }) => {

    const require = [
      day,
      name,
      year,
      month,
      gender,
      last_name,
      courierId,
      region_id,
      vehicle_type_id,
      additional_phone
    ];

    const vehicleRequire = [
      day,
      year,
      name,
      month,
      gender,
      car_name,
      last_name,
      region_id,
      courierId,
      car_number,
      vehicle_type_id,
      additional_phone
    ];

    if (vehicle_type_id && vehicle_type_id >= 1) {
      return require.every(field => field !== null && field.toString().length < 50);
    } else {
      return vehicleRequire.every(field => field !== null && field.toString().length < 50);
    }

  },
  fn: ({
         name,
         gender,
         car_name,
         last_name,
         region_id,
         courierId,
         car_number,
         vehicle_type_id,
         additional_phone,
         year, month, day
       }) => ({
    name,
    gender,
    car_name,
    last_name,
    courierId,
    region_id,
    car_number,
    date_of_birth: (year && month && day) && `${year}-${month}-${day}`,
    vehicle_type_id,
    additional_phone: additional_phone.replace(/\s/g, "")
  }),
  target: updateCourierDataFx
});

sample({
  source: $combinedInputs,
  clock: formSubmitted,
  filter: ({
             name,
             gender,
             last_name,
             region_id,
             car_name,
             car_number,
             vehicle_type_id,
             additional_phone,
             year, month, day
           }) => name === null || last_name === null || gender === null || additional_phone === null || additional_phone.length === 0 || region_id === null || vehicle_type_id === null || year === null || month === null || day === null
    || ((vehicle_type_id && vehicle_type_id <= 1) && (car_name === null || car_number === null)),
  target: fillAllErrorInputsFx
});


sample({
  source: $registrationStep,
  clock: getActivateCodeFx.done,
  fn: (source) => source,
  target: redirectToPageFx
});

sample({
  source: $registrationStep,
  clock: activatePhoneFx.done,
  fn: (source) => source,
  target: redirectToPageFx
});

guard({
  source: $createdCourierId,
  clock: pageOpened,
  filter: (id, _): id is number => id !== null,
  target: saveCourierIdFx
});

sample({
  clock: [updateCourierDataFx.done, pageClosed],
  target: deleteCourierIdFx
})

sample({
  clock: goBackClicked,
  target: redirectToHomeFx
})