import {httpClient} from "./index";
import {Region, RegisterApplication, Registration} from "./api.types";

export const fetchRegions = (): Promise<Region[]> => {
  return httpClient({
    url: 'register-application/regions'
  }).then(response => response.data)
};

export const postPhoneNumber = (data: Registration): Promise<{ smsCode: string }> => {
  return httpClient({
    method: 'post',
    url: 'register-application/code',
    data
  }).then(response => response.data)
};

export const postActivatePhone = (data: Registration) => {
  return httpClient({
    method: 'post',
    url: 'register-application',
    data
  }).then(response => response.data)
};

export const putRegisterApplication = (data: RegisterApplication) => {
  return httpClient({
    method: 'put',
    url: `register-application/${data.courierId}`,
    data
  })
}