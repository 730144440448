import {Grid, Space, Typography} from "@express-24/theseus-ui";
import {useTranslation} from "react-i18next";
// @ts-ignore
import Social from '../../../asserts/Social.png';
import './style.css';

const docs = [
  {text: 'passport'},
  {text: 'smartphone_base_android_8_and_higher'},
  {text: 'payment_card'},
  {text: 'driver_licence_etc'}];

export const CongratsPage = () => {
  const {t} = useTranslation();

  return (
    <div className='congrats-wrapper'>
      <Typography
        semibold
        level='h4'
        align='center'
      >
        {t('application_accepted')}
      </Typography>
      <Space margin={[8, 0]}/>
      <Typography
        level='text'
        align='center'
      >
        {t('wait_sms')}
      </Typography>
      <Space margin={[85, 0]}>
        <img src={Social} alt='social'/>
      </Space>
      <Typography
        semibold
        align='left'
        level='text'
      >
        {t('to_connect_in_the_office_u_must')}
      </Typography>
      <Space margin={[8,0]}/>
      <Grid.Row gutter={[0, 16]}>
        {docs.map(({text}) => <Grid.Column size={12}>- {t(text)}</Grid.Column>)}
      </Grid.Row>
    </div>
  );
};