import {Alert, Button, Grid, Input, InputMask, Select, Space, Typography} from "@express-24/theseus-ui";
import {useStore} from "effector-react";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
  $createdCourierId,
  $day,
  $month,
  $movementTypeBrand,
  $movementTypeNumber,
  $name,
  $regions,
  $selectedDeliveryType,
  $selectedGender,
  $selectedRegion,
  $successfulCreatedText,
  $surname,
  $year,
  dayChanged,
  deliveryTypeSelected,
  formSubmitted,
  genderSelected,
  monthChanged,
  movementTypeBrandChanged,
  movementTypeNumberChanged,
  nameChanged,
  pageClosed,
  pageOpened,
  regionSelected,
  surnameChanged,
  updateCourierDataFx,
  yearChanged
} from "../model/model";
import './style.css';
import {useHistory} from "react-router";

export const Registration = () => {
  const day = useStore($day);
  const {push} = useHistory();
  const year = useStore($year);
  const name = useStore($name);
  const {t} = useTranslation();
  const month = useStore($month);
  const regions = useStore($regions);
  const surname = useStore($surname);
  const courierId = useStore($createdCourierId);
  const selectedGender = useStore($selectedGender);
  const selectedRegion = useStore($selectedRegion);
  const successText = useStore($successfulCreatedText);
  const movementTypeBrand = useStore($movementTypeBrand);
  const movementTypeNumber = useStore($movementTypeNumber);
  const selectedDeliveryType = useStore($selectedDeliveryType);
  const courierDataUpdating = useStore(updateCourierDataFx.pending);

  useEffect(() => {
    pageOpened();

    return () => pageClosed();
  }, []);

  useEffect(() => {
    if (!courierId) {
      push('/');
    }
  }, [courierId, push]);

  const handleRegistrationSubmit = useCallback((e) => {
    e.preventDefault();
    formSubmitted();
  }, []);

  return (
    <Space padding={[40, 0, 20, 0]}>
      {successText && (
        <Alert
          visible
          onOk={() => push('/congrats')}
          okText={t('continue')}
          title={successText}
          description={t('wait_sms') || undefined}
        />)}
      <form
        key='registrationCourier'
        onSubmit={handleRegistrationSubmit}
      >
        <Grid.Container>
          <Grid.Row gutter={[8, 24]}>
            <Grid.Column size={12}>
              <Typography level='h1'>{t('registration')}</Typography>
              <Space margin={[8, 0, 0, 0]}/>
              <Typography
                level='sub-text'
                kind='secondary'
              >
                {t('registration_subtext')}
              </Typography>
            </Grid.Column>
            <Grid.Column size={12}>
              <Input
                fluid
                value={name || ''}
                label={t('name') || ''}
                message={t('input_subtext') || ''}
                status={(name?.replace(/\s/g, "").length === 0 || name?.replace(/\s/g, "").length === 50) ? 'error' : undefined}
                onChange={({target}) => {
                  let str = target.value.replace(/[^A-Za-z]/ig, '');
                  str.length <= 50 && nameChanged(str);
                }}
              />
            </Grid.Column>
            <Grid.Column size={12}>
              <Input
                fluid
                value={surname || ''}
                label={t('surname') || ''}
                message={t('input_subtext') || ''}
                status={(surname?.replace(/\s/g, "").length === 0 || surname?.replace(/\s/g, "").length === 50) ? 'error' : undefined}
                onChange={({target}) => {
                  let str = target.value.replace(/[^A-Za-z]/ig, '');
                  str.length <= 50 && surnameChanged(str);
                }}
              />
            </Grid.Column>
            <Grid.Column size={4}>
              <div className='date-input'>
                <InputMask
                  mask='9999'
                  onChange={yearChanged}
                >
                  <Input
                    fluid
                    allowClear
                    type='tel'
                    onChange={() => {
                    }}
                    label={t('year') || ''}
                    max={new Date().getFullYear()}
                    status={year?.length === 0 ? 'error' : undefined}
                  />
                </InputMask>
              </div>
            </Grid.Column>
            <Grid.Column size={4}>
              <div className='date-input'>
                <InputMask
                  mask='99'
                  onChange={monthChanged}
                >
                  <Input
                    fluid
                    min={1}
                    max={12}
                    allowClear
                    type='tel'
                    onChange={() => {
                    }}
                    label={t('month') || ''}
                    status={month?.length === 0 ? 'error' : undefined}
                  />
                </InputMask>
              </div>
            </Grid.Column>
            <Grid.Column size={4}>
              <div className='date-input'>
                <InputMask
                  mask='99'
                  onChange={dayChanged}
                >
                  <Input
                    fluid
                    min={1}
                    max={31}
                    allowClear
                    type='tel'
                    onChange={() => {
                    }}
                    label={t('day') || ''}
                    status={day?.length === 0 ? 'error' : undefined}
                  />
                </InputMask>
              </div>
            </Grid.Column>
            <Grid.Column size={12}>
              <Select
                fluid
                mode='single'
                kind='dropdown'
                selected={selectedGender}
                onSelect={genderSelected}
                label={t('gender') || ''}
                options={[
                  {
                    value: 'M',
                    label: t('M')
                  },
                  {
                    value: 'F',
                    label: t('F')
                  }
                ]}
              />
            </Grid.Column>
            <Grid.Column size={12}>
              <Select
                fluid
                mode='single'
                kind='dropdown'
                onSelect={regionSelected}
                selected={selectedRegion}
                label={t('region') || ''}
                options={regions?.map(region => ({
                  value: region.id,
                  label: 'ru' ? region.name_ru : region.name_uz
                })) || []}
              />
            </Grid.Column>
            <Grid.Column size={12}>
              <Select
                fluid
                mode='single'
                kind='dropdown'
                onSelect={deliveryTypeSelected}
                selected={selectedDeliveryType}
                label={t('delivery_method') || ''}
                message={t('delivery_subtext') || ''}
                options={[
                  {
                    value: 1,
                    label: t('car')
                  },
                  {
                    value: 2,
                    label: t('scooter')
                  },
                  {
                    value: 3,
                    label: t('bike_courier')
                  },
                  {
                    value: 4,
                    label: t('foot_courier')
                  }
                ]}
              />
            </Grid.Column>
            {selectedDeliveryType === 1 && (
              <>
                <Grid.Column size={12}>
                  <Input
                    fluid
                    value={movementTypeNumber || ''}
                    onChange={movementTypeNumberChanged}
                    label={t(`number_of_${selectedDeliveryType}`) || ''}
                    status={(movementTypeNumber?.replace(/\s/g, '').length === 0 || movementTypeNumber?.replace(/\s/g, '').length === 15)
                      ? 'error' : undefined}
                    message={t('helper_text_car_number') || ''}
                  />
                </Grid.Column>
                <Grid.Column size={12}>
                  <Input
                    fluid
                    value={movementTypeBrand || ''}
                    onChange={movementTypeBrandChanged}
                    label={t(`brand_of_${selectedDeliveryType}`) || ''}
                    status={(movementTypeBrand?.replace(/\s/g, "").length === 0 || movementTypeBrand?.replace(/\s/g, "").length === 50)
                      ? 'error' : undefined}
                  />
                </Grid.Column>
              </>
            )}
            <Grid.Column size={12}>
              <Space margin={[8, 0, 0, 0]}/>
              <Button
                fluid
                size='large'
                type='submit'
                kind='primary'
                disabled={courierDataUpdating}
                loading={courierDataUpdating}
              >
                {t('send')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid.Container>
      </form>
    </Space>
  );
};