import {Button, Grid, Icon, Input, InputMask, Space, Timer, Typography} from "@express-24/theseus-ui";
import {
  $code,
  $codeErrorMessage,
  $phone,
  $resendCodeStatus,
  activatePhoneFx,
  codeConfirmed,
  codeReceivedAgain,
  confirmCodeChanged,
  goBackClicked,
  toggleCodeResend
} from "../../registration/model/model";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useStore} from "effector-react";

export const CodeAccept = () => {
  const {t} = useTranslation();
  const code = useStore($code);
  const phone = useStore($phone);
  const resendCodeStatus = useStore($resendCodeStatus);
  const codeErrorMessage = useStore($codeErrorMessage);
  const activateCodeLoading = useStore(activatePhoneFx.pending);

  const handleConfirmCode = useCallback((e) => {
    e.preventDefault();
    codeConfirmed();
  }, []);

  return (
    <Space padding={[104, 0]}>
      <form
        key='codeConfirm'
        onSubmit={handleConfirmCode}
      >
        <Grid.Row gutter={[0, 48]}>
          <Grid.Column size={12}>
            <div
              onClick={() => goBackClicked()}
              className='arrow-wrapper'
            >
              <Icon
                size={24}
                name='arrow-left'
              />
            </div>
            <Typography
              semibold
              level='h3'
            >
              {t('enter_code')}
            </Typography>
            <Typography
              level='sub-text'
              kind='secondary'
            >
              {t('send_to_phone', {phone})}
            </Typography>
          </Grid.Column>
          <Grid.Column size={12}>
            <InputMask
              mask='9999'
              defaultValue={code}
              onChange={confirmCodeChanged}
            >
              <Input
                fluid
                allowClear
                type='tel'
                label={t('code_number') || ''}
                //@ts-ignore
                message={
                  resendCodeStatus && (
                    <div className='resend-code-wrapper'>
                      {t('resend_code')}
                      <Space margin={[0, 4]}>
                        <Timer
                          seconds={60}
                          onTimerEnd={() => toggleCodeResend()}
                        />
                      </Space>
                    </div>
                  )
                }
                status={codeErrorMessage ? 'error' : undefined}
              />
            </InputMask>
            <Space margin={[2, 0]}/>
            {
              !resendCodeStatus && (
                <Button
                  size='small'
                  kind='secondary'
                  onClick={() => codeReceivedAgain()}
                >
                  {t('retrieve')}
                </Button>
              )
            }
          </Grid.Column>
          <Grid.Column size={12}>
            <Space margin={[16, 0, 0, 0]}/>
            <Button
              fluid
              size='large'
              type='submit'
              loading={activateCodeLoading}
              disabled={activateCodeLoading}
            >
              {t('continue')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </form>
    </Space>
  );
};