import {Login} from "./pages/login/pages";
import {CongratsPage} from "./pages/congrats/pages";
import {Registration} from "./pages/registration/pages";
import {CodeAccept} from "./pages/login/pages/code-accept";

type Props = {
  path: string,
  component: any
  exact?: boolean
}
export const ROUTES: Props[] = [
  {
    path: '/',
    exact: true,
    component: Login
  },
  {
    exact: true,
    path: '/code-confirm',
    component: CodeAccept
  },
  {
    exact: true,
    path: '/registration',
    component: Registration
  },
  {
    exact: true,
    path: '/congrats',
    component: CongratsPage
  }
];