import React from 'react';
import {Route, Switch} from "react-router-dom";
import {ROUTES} from "./routes";
import {Space} from "@express-24/theseus-ui";

function App() {

  return (
    <Space padding={[0, 16]}>
      <Switch>
        {ROUTES.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Space>
  );
}

export default App;