import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ru from './ru.json';
import uz from './uz.json';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false
    },
    contextSeparator: ',',
    resources: {
      ru: {
        translation: ru
      },
      uz: {
        translation: uz
      }
    }
  });

export default i18n;